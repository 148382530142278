
import React, { useState } from 'react';
import { documentationData } from './data';
import './App.css';

function App() {
  const [expanded, setExpanded] = useState({});
  const [highlighted, setHighlighted] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const toggleExpand = (path) => {
    setExpanded(prev => ({
      ...prev,
      [path]: !prev[path]
    }));
  };

  const handleItemClick = (path, item) => {
    setHighlighted(item);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

const handleSearch = (event) => {
  const value = event.target.value.toLowerCase();
  setSearchTerm(value);

  if (value === "") {
    setExpanded({});
  } else {
    const newExpanded = {};

    // Rekurencyjna funkcja przeszukująca dane i rozwijająca rodziców
    const searchInData = (data, path = '') => {
      let found = false;

      // Jeśli data to tablica, przeszukujemy jej elementy
      if (Array.isArray(data)) {
        data.forEach((item, index) => {
          const newPath = path + (path ? '-' : '') + index;
          if (typeof item === 'string' && item.toLowerCase().includes(value)) {
            newExpanded[newPath] = true;
            found = true;
          } else if (typeof item === 'object') {
            const res = searchInData(item, newPath);
            if (res) {
              newExpanded[newPath] = true;
              found = true;
            }
          }
        });
      } else if (typeof data === 'object') {
        // Jeśli data to obiekt, przeszukujemy jego klucze i wartości
        Object.entries(data).forEach(([key, val]) => {
          const newPath = path + (path ? '-' : '') + key;
          
          // Sprawdzamy, czy klucz obiektu pasuje do zapytania
          if (key.toLowerCase().includes(value)) {
            newExpanded[newPath] = true;
            found = true;
          }

          // Przeszukujemy zagnieżdżone wartości (głębsze poziomy drzewa)
          const res = searchInData(val, newPath);
          if (res) {
            newExpanded[newPath] = true;
            found = true;
          }
        });
      }

      // Jeśli znaleźliśmy dopasowanie, rozwijamy wszystkie nadrzędne ścieżki
      if (found) {
        let parentPath = path;
        while (parentPath) {
          newExpanded[parentPath] = true;
          const lastDashIndex = parentPath.lastIndexOf('-');
          if (lastDashIndex === -1) {
            break;
          }
          parentPath = parentPath.substring(0, lastDashIndex);
        }
      }

      return found;
    };

    // Przeszukujemy całą strukturę danych
    searchInData(documentationData);
    setExpanded(newExpanded);
  }
};





const renderList = (data, path = '') => {
  if (Array.isArray(data)) {
    return data.map((item, index) => {
      const newPath = path + (path ? '-' : '') + index;
      if (typeof item === 'string') {
        return (
          <li key={newPath} onClick={() => handleItemClick(newPath, item)}>
            {item}
          </li>
        );
      } else if (typeof item === 'object') {
        return (
          Object.entries(item).map(([key, value]) => (
            <div key={newPath + key} className={expanded[newPath + key] ? 'list-group expanded-group' : 'list-group'}>
              <span onClick={() => toggleExpand(newPath + key)} className="toggle-button">
                <i className={expanded[newPath + key] ? "fas fa-minus" : "fas fa-plus"}></i> {key}
              </span>
              <ul className={expanded[newPath + key] ? 'expanded' : ''}>{renderList(value, newPath + key)}</ul>
            </div>
          ))
        );
      }
      return null;
    });
  } else if (typeof data === 'object') {
    return Object.entries(data).map(([key, value], index) => {
      const newPath = path + (path ? '-' : '') + key;
      return (
        <div key={newPath} className={expanded[newPath] ? 'list-group expanded-group' : 'list-group'}>
          <span onClick={() => toggleExpand(newPath)} className="toggle-button">
            <i className={expanded[newPath] ? "fas fa-minus" : "fas fa-plus"}></i> {key}
          </span>
          <ul className={expanded[newPath] ? 'expanded' : ''}>{renderList(value, newPath)}</ul>
        </div>
      );
    });
  }
  return null;
};


  return (
    <div className="container">
      <h1 style={{ fontSize: '24px' }}>Wybrano: {highlighted}</h1>
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Wyszukaj..."
        className="search-input"
        style={{ fontSize: '18px' }}
      />
      <div style={{ fontSize: '18px' }}>{renderList(documentationData)}</div>
    </div>
  );
}

export default App;