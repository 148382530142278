export const documentationData = {
  "A": {
    "CLFMEBZ": ["GITD", {"chujka":["MHYFAa", {"Klosz":["Duoa", {"Kolka":["CTC", "FFF"]}]}]}],
    "VJYNBEI": ["MHYF", "ZRKH"]
  },
  "B": {
    "XRFJJWP": ["IMAT", "VTMA"],
    "UWIDJFN": ["VMGA", "AZDD"]
  },
  "C": {
    "SHFDQNS": ["TJNZ", "HRYD"],
    "FTXSJZE": ["LJCB", "PZHI"]
  },
  "D": {
    "VNETSRF": ["KJNU", "CFSZ"],
    "QFYUDPN": ["FRCG", "KJNG"]
  },
  "E": {
    "DNUMTYU": ["NQCL", "BIAO"],
    "ACUOWXX": ["XCIV", "WHGX"]
  },
  "F": {
    "KQJXTVM": ["VCZO", "LLEE"],
    "JFJFQBK": ["QQDD", "XIFF"]
  },
  "G": {
    "NVTVYKP": ["HNJZ", "CMBE"],
    "HPWVKQL": ["LCXM", "JDMF"]
  },
  "H": {
    "JJMXPQX": ["FZDF", "VSSA"],
    "TAMTPGX": ["HMDW", "OLZC"]
  },
  "I": {
    "YHCHCFM": ["ADZB", "QPFT"],
    "KWWGQSB": ["XTEF", "DQZE"]
  },
  "J": {
    "YVMWPFP": ["BMCI", "MQEK"],
    "YNVYEVL": ["UINM", "PGUA"]
  },
  "K": {
    "BFGPYRO": ["UCXM", "XWTG"],
    "LWRFCAY": ["YZKJ", "UFHF"]
  },
  "L": {
    "FYVYEET": ["SNCK", "YZOZ"],
    "DRUVPFG": ["FONE", "RAPI"]
  },
"M": {
    "SHFDQNS": ["TJNZ", "HRYD"],
    "FTXSJZE": ["LJCB", "PZHI"]
  },
  "N": {
    "VNETSRF": ["KJNU", "CFSZ"],
    "QFYUDPN": ["FRCG", "KJNG"]
  },
  "O": {
    "DNUMTYU": ["NQCL", "BIAO"],
    "ACUOWXX": ["XCIV", "WHGX"]
  },
  "U": {
    "KQJXTVM": ["VCZO", "LLEE"],
    "JFJFQBK": ["QQDD", "XIFF"]
  },
  "P": {
    "NVTVYKP": ["HNJZ", "CMBE"],
    "HPWVKQL": ["LCXM", "JDMF"]
  },
  "R": {
    "JJMXPQX": ["FZDF", "VSSA"],
    "TAMTPGX": ["HMDW", "OLZC"]
  },
  "S": {
    "YHCHCFM": ["ADZB", "QPFT"],
    "KWWGQSB": ["XTEF", "DQZE"]
  },
  "T": {
    "YVMWPFP": ["BMCI", "MQEK"],
    "YNVYEVL": ["UINM", "PGUA"]
  },
  "Z": {
    "BFGPYRO": ["UCXM", "XWTG"],
    "LWRFCAY": ["YZKJ", "UFHF"]
  },
  "Ź": {
    "FYVYEET": ["SNCK", "YZOZ"],
    "DRUVPFG": ["FONE", "RAPI"]
  },
"1": {
    "NVTVYKP": ["HNJZ", "CMBE"],
    "HPWVKQL": ["LCXM", "JDMF"]
  },
  "2": {
    "JJMXPQX": ["FZDF", "VSSA"],
    "TAMTPGX": ["HMDW", "OLZC"]
  },
  "3": {
    "YHCHCFM": ["ADZB", "QPFT"],
    "KWWGQSB": ["XTEF", "DQZE"]
  },
  "4": {
    "YVMWPFP": ["BMCI", "MQEK"],
    "YNVYEVL": ["UINM", "PGUA"]
  },
  "5": {
    "BFGPYRO": ["UCXM", "XWTG"],
    "LWRFCAY": ["YZKJ", "UFHF"]
  },
  "6": {
    "FYVYEET": ["SNCK", "YZOZ"],
    "DRUVPFG": ["FONE", "RAPI"]
  },
};
